import React, { useState } from 'react';
import {
  Card,
  CardActionArea,
  CardContent,
  Typography,
  Select,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import ImageWithCircleOverlay from './ImageWithCircleOverlay';
import { images } from '../images';

const EditorDialog2 = ({ open, onClose, onSave, editedData, handleChange, fullScreen = true }) => {
  const index = editedData?.selector;
  let step = editedData;
  const height = '100px';

  if (typeof step?.image === 'object') {
    const xx = Object.keys(images).find((a) => images[a].name === step.image.name);
    step = {
      ...step,
      image: xx,
    };
    editedData = { ...editedData, image: xx };
  }

  const handleMarkChange = (value) => {
    handleChange('mark', value);
    // Reset additional mark-related fields when changing mark type
    handleChange('markRadius', '');
    handleChange('markXPercent', '');
    handleChange('markYPercent', '');
    handleChange('markRectangleWidth', '');
    handleChange('markRectangleHeight', '');
  };

  return (
    <Dialog fullScreen={fullScreen} open={open} onClose={onClose}>
      <DialogTitle>{editedData !== null ? 'Edit Item2 ' : 'New Item'}</DialogTitle>
      <DialogContent style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '16px' }}>
        <div>
          <Select
            label="Type"
            value={editedData?.type || 'slide'}
            onChange={(e) => handleChange('type', e.target.value)}
          >
            <MenuItem value="slide">Slide</MenuItem>
            <MenuItem value="link">Link</MenuItem>
            <MenuItem value="help">Help</MenuItem>
          </Select>
          <TextField
            label="Selector"
            value={editedData?.selector || ''}
            onChange={(e) => handleChange('selector', e.target.value)}
          />
          <TextField
            label="Content"
            value={editedData?.content || ''}
            onChange={(e) => handleChange('content', e.target.value)}
          />
          <TextField
            label="Description"
            value={editedData?.description || ''}
            onChange={(e) => handleChange('description', e.target.value)}
          />
          <Autocomplete
            options={Object.keys(images)}
            getOptionLabel={(option) => images[option]?.image + ' ' + images[option]?.name}
            value={step?.image || ''}
            onChange={(e, value) => handleChange('image', value)}
            renderInput={(params) => (
              <TextField {...params} label="Image" variant="outlined" />
            )}
          />
          <Select
            label="Mark"
            value={editedData?.mark || ''}
            onChange={(e) => handleMarkChange(e.target.value)}
          >
            <MenuItem value={null}>Nincs</MenuItem>
            <MenuItem value="circle">Circle</MenuItem>
            <MenuItem value="rect">Rectangle</MenuItem>
          </Select>
          {editedData?.mark === 'circle' && (
            <>
              <TextField
                label="Mark Radius"
                value={editedData?.markRadius || ''}
                onChange={(e) => handleChange('markRadius', e.target.value)}
              />
              <TextField
                label="Mark X Percent"
                value={editedData?.markXPercent || ''}
                onChange={(e) => handleChange('markXPercent', e.target.value)}
              />
              <TextField
                label="Mark Y Percent"
                value={editedData?.markYPercent || ''}
                onChange={(e) => handleChange('markYPercent', e.target.value)}
              />
            </>
          )}
          {editedData?.mark === 'rect' && (
            <>
              <TextField
                label="Mark Rectangle Width"
                value={editedData?.markRectangleWidth || ''}
                onChange={(e) => handleChange('markRectangleWidth', e.target.value)}
              />
              <TextField
                label="Mark Rectangle Height"
                value={editedData?.markRectangleHeight || ''}
                onChange={(e) => handleChange('markRectangleHeight', e.target.value)}
              />
              <TextField
                label="Mark X Percent"
                value={editedData?.markXPercent || ''}
                onChange={(e) => handleChange('markXPercent', e.target.value)}
              />
              <TextField
                label="Mark Y Percent"
                value={editedData?.markYPercent || ''}
                onChange={(e) => handleChange('markYPercent', e.target.value)}
              />
            </>
          )}
        </div>
        <div>
          <Card key={`Card${index}`} sx={{ height: height }}>
            <CardActionArea key={`CardActionArea${index}`}>
              <CardContent key={`CardContent${index}`}>
                <Typography key={`Typography${index}`} gutterBottom variant="h5" component="div">
                  {step.content}
                </Typography>
                <Typography key={`Typography2${index}`} variant="body2" color="text.secondary">
                  {step.description}
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
          <div className='centerContent2 helpImg'>
            <ImageWithCircleOverlay key={`ImageWithCircleOverlay${index}`} step={step} imageHeight={'500'} />
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={onSave}>Save Changes</Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditorDialog2;
