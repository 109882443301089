export const images = {
    signin: {
        name: 'Bejelentkezés',
        image: 'authentication/signin.png',
    },
    signup: {
        name: 'Regisztráció',
        image: 'authentication/signup.png',
    },
    forgotpassword: {
        name: 'Elfelejtett jelszó',
        image: 'authentication/forgotpassword.png',
    },
    reset: {
        name: 'Jelszó megváltoztatása',
        image: 'authentication/reset.png',
    },
    dashboard: {
        name: 'Kezdőoldal',
        image: 'dashboard/dashboard.png',
    },
    profile: {
        name: 'Profil',
        image: 'profile/profile.png',
    },
    qr: {
        name: 'Csoport QR kód',
        image: 'subscribe/qr.png',
    },
    scan: {
        name: 'Csoport QR kód beolvasása',
        image: 'subscribe/scan.png',
    },
    dashboard2: {
        name: 'Kezdőoldal',
        image: 'dashboard/dashboard2.png',
    },
    evaluations1: {
        name: 'Értékelések listája',
        image: 'evaluations/evaluations1.png',
    },
    evaluations2: {
        name: 'Értékelések listája',
        image: 'evaluations/evaluations2.png',
    },
    evaluations3: {
        name: 'Értékelés',
        image: 'evaluations/evaluations3.png',
    },
    manage1: {
        name: 'Kérdőívek',
        image: 'manage/manage1.png',
    },
    start1: {
        name: 'Kérdőív készítés 1.',
        image: 'manage/start1.png',
    },
    start2: {
        name: 'Kérdőív készítés 2.',
        image: 'manage/start2.png',
    },
    start3: {
        name: 'Kérdőív készítés 3.',
        image: 'manage/start3.png',
    },
    start4: {
        name: 'Kérdőív készítés 4.',
        image: 'manage/start4.png',
    },
    start5: {
        name: 'Kérdőív készítés 5.',
        image: 'manage/start5.png',
    },
    start6: {
        name: 'Kérdőív készítés 6.',
        image: 'manage/start6.png',
    },
    start7: {
        name: 'Kérdőív készítés 7.',
        image: 'manage/start7.png',
    },
    start8: {
        name: 'Kérdőív készítés 8.',
        image: 'manage/start8.png',
    },
    start9: {
        name: 'Kérdőív készítés 9.',
        image: 'manage/start9.png',
    },
    groups1: {
        name: 'Csoportok',
        image: 'groups/mygroups1.png',
    },
    groups2: {
        name: 'Csoport - adatlap',
        image: 'groups/mygroups2.png',
    },
    groups3: {
        name: 'Csoport - felhasználók',
        image: 'groups/mygroups3.png',
    },
    groups4: {
        name: 'Csoport - adminisztráció',
        image: 'groups/mygroups4.png',
    },
    groups5: {
        name: 'Csoport - jelentkezők',
        image: 'groups/mygroups5.png',
    },
    profile1: {
        name: 'Profil',
        image: 'profile/profile1.png',
    },
    profile2: {
        name: 'Profilkép feltöltés',
        image: 'profile/profile2.png',
    },
    profile3: {
        name: 'Profilkép igazítás',
        image: 'profile/profile3.png',
    },
    profile4: {
        name: 'Profil - új profilkép',
        image: 'profile/profile4.png',
    },
    questionnaire1: {
        name: 'Kérdőív statisztika',
        image: 'questionnaires/questionnaire1.png',
    },
    sampling1: {
        name: 'Kitöltés 1.',
        image: 'sampling/sampling1.png',
    },
    sampling2: {
        name: 'Kitöltés 2.',
        image: 'sampling/sampling2.png',
    },
    sampling3: {
        name: 'Kitöltés 3.',
        image: 'sampling/sampling3.png',
    },
    sampling4: {
        name: 'Kitöltés 4.',
        image: 'sampling/sampling4.png',
    },
    sampling5: {
        name: 'Kitöltés 5.',
        image: 'sampling/sampling5.png',
    },
    sampling6: {
        name: 'Kitöltés 6.',
        image: 'sampling/sampling6.png',
    },
    sampling7: {
        name: 'Kitöltés 5.',
        image: 'sampling/sampling7.png',
    },
    sampling8: {
        name: 'Kitöltés 8.',
        image: 'sampling/sampling8.png',
    },
    sampling9: {
        name: 'Kitöltés 9.',
        image: 'sampling/sampling9.png',
    },
    sampling10: {
        name: 'Kitöltés 10.',
        image: 'sampling/sampling10.png',
    },
    sampling11: {
        name: 'Kitöltés 11.',
        image: 'sampling/sampling11.png',
    },
    sampling12: {
        name: 'Kitöltés 12.',
        image: 'sampling/sampling12.png',
    },
    sampling13: {
        name: 'Kitöltés 13.',
        image: 'sampling/sampling13.png',
    },
    sampling14: {
        name: 'Kitöltés 14.',
        image: 'sampling/sampling14.png',
    },
    sampling15: {
        name: 'Kitöltés 15.',
        image: 'sampling/sampling15.png',
    },
    subscribe1: {
        name: 'Feliratkozás 1.',
        image: 'subscribe/subscribe1.png',
    },
    subscribe2: {
        name: 'Feliratkozás 2.',
        image: 'subscribe/subscribe2.png',
    },
    subscribe3: {
        name: 'Feliratkozás 3.',
        image: 'subscribe/subscribe3.png',
    },
    subscribe4: {
        name: 'Feliratkozás 4.',
        image: 'subscribe/subscribe4.png',
    },
    subscribe5: {
        name: 'Feliratkozás 5.',
        image: 'subscribe/subscribe5.png',
    },
    subscribe6: {
        name: 'Feliratkozás 6.',
        image: 'subscribe/subscribe6.png',
    },
    install1: {
        name: 'Telepítés 1.',
        image: 'install/install1.png',
    },
    install2: {
        name: 'Telepítés 2.',
        image: 'install/install2.png',
    },
}