import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Paper, Typography } from '@mui/material';
import { useTheme } from '@mui/system';

const RootDiv = styled.div`
  text-align: center;
  padding: ${({ theme }) => theme.spacing(2)};
  position: relative;
`;

const Image = styled.img`
  max-width: 200px;
  max-height: 400px;
`;

const ObjectContainer = styled.div`
  position: absolute;
  transition: all 0.5s ease-in-out;
`;

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const AnimatedSlide = ({ imageUrl, text, animations }) => {
  const theme = useTheme();
  const [objects, setObjects] = useState([]);
  const [currentAnimation, setCurrentAnimation] = useState(0);

  const executeAnimation = async (animation) => {
    switch (animation.appearance) {
      case 'időzítő':
        await delay(1000); // 1 mp-es időzítés
        break;
      case 'előzővel':
        break;
      case 'kattintásra':
        await new Promise((resolve) => document.addEventListener('click', resolve, { once: true }));
        break;
      default:
        break;
    }

    executeObjectAnimation(animation);
  };

  const executeObjectAnimation = (animation) => {
    const updatedObjects = objects.map((obj) =>
      obj.id === animation.id ? { ...obj, ...animation.options } : obj
    );
    setObjects(updatedObjects);
    setCurrentAnimation((prev) => prev + 1);
  };

  useEffect(() => {
    const runAnimations = async () => {
      for (const animation of animations) {
        await executeAnimation(animation);
      }
    };

    runAnimations();
  }, [animations]);

  return (
    <RootDiv theme={theme}>
      <Image src={imageUrl} alt="Slide" />
      <Paper as={ObjectContainer}>
        <Typography>{text}</Typography>
      </Paper>
      {objects.map((obj) => (
        <ObjectContainer
          key={obj.id}
          style={{
            left: `${obj.options.x}px`,
            top: `${obj.options.y}px`,
            width: `${obj.options.width}px`,
            height: `${obj.options.height}px`,
          }}
        >
          {obj.animation_type === 'megjelenés' && <div>Megjelenés</div>}
          {obj.animation_type === 'eltüntetés' && <div>Eltüntetés</div>}
          {obj.animation_type === 'mozgatás' && <div>Mozgatás</div>}
          {obj.animation_type === 'zoom' && <div>Zoom</div>}
        </ObjectContainer>
      ))}
    </RootDiv>
  );
};

export default AnimatedSlide;
