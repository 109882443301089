import React from 'react';
import AnimatedSlide from '../../common/animations/AnimatedSlide';
// import AnimatedSlide from './AnimatedSlide'; // Az AnimatedSlide komponens importálása

const ExampleAnimation = () => {
  // Animációs lépések definiálása
  const animations = [
    {
      id: 1,
      animation_type: 'megjelenés',
      object_type: 'kép',
      appearance: 'időzítő',
      options: {
        x: 0,
        y: 0,
        width: 200,
        height: 150,
      },
    },
    {
      id: 2,
      animation_type: 'megjelenés',
      object_type: 'szöveg',
      appearance: 'időzítő',
      options: {
        x: 0,
        y: 180,
      },
    },
    {
      id: 3,
      animation_type: 'megjelenés',
      object_type: 'téglalap',
      appearance: 'időzítő',
      options: {
        x: 50,
        y: 50,
        width: 100,
        height: 75,
      },
    },
    {
      id: 4,
      animation_type: 'mozgatás',
      object_type: 'téglalap',
      appearance: 'időzítő',
      options: {
        x: 70,
        y: 50,
      },
    },
    {
      id: 5,
      animation_type: 'zoom',
      object_type: 'téglalap',
      appearance: 'időzítő',
      options: {
        width: 200,
        height: 150,
      },
    },
    {
      id: 6,
      animation_type: 'eltüntetés',
      object_type: 'téglalap',
      appearance: 'időzítő',
    },
  ];

  return (
    <AnimatedSlide
      imageUrl="https://kerdoiv.b612.hu/help/authentication/signin.png"
      text="Ez itt a szöveg"
      animations={animations}
    />
  );
};

export default ExampleAnimation;
