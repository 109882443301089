import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import Editor2 from './Editor2';
import FSPFieldIcon2 from './FSPFieldIcon2';

const EditorDialog = ({ open, onClose, onSave, editedData, handleChange, fullScreen = true }) => {
    const handleStepChange = (field, value, stepIndex) => {
        const newSteps = [...editedData.steps];
        newSteps[stepIndex] = { ...newSteps[stepIndex], [field]: value };
        handleChange('steps', newSteps);
    };

    return (
        <Dialog fullScreen={fullScreen} open={open} onClose={onClose}>
            <DialogTitle>{editedData !== null ? 'Edit Item1' : 'New Item'}</DialogTitle>
            <DialogContent>
                <TextField
                    label="Content"
                    value={editedData?.content || ''}
                    onChange={(e) => handleChange('content', e.target.value)}
                />
                <TextField
                    label="Description"
                    value={editedData?.description || ''}
                    onChange={(e) => handleChange('description', e.target.value)}
                />
                {/* Új mezők hozzáadása */}
                <TextField
                    label="Icon"
                    value={editedData?.icon || ''}
                    onChange={(e) => handleChange('icon', e.target.value)}
                />
                <FSPFieldIcon2
                    field={'icon' }
                    handleChangeArray={(e, a) => handleChange('icon', a)}
                    newRecord={ editedData}
                />
                <TextField
                    label="Type"
                    value={editedData?.type || ''}
                    onChange={(e) => handleChange('type', e.target.value)}
                />
                <FormControl fullWidth>
                    <InputLabel htmlFor="isAdmin">isAdmin</InputLabel>
                    <Select
                        value={editedData?.isAdmin || false}
                        onChange={(e) => handleChange('isAdmin', e.target.value)}
                        inputProps={{ id: 'isAdmin' }}
                    >
                        <MenuItem value={true}>True</MenuItem>
                        <MenuItem value={false}>False</MenuItem>
                    </Select>
                </FormControl>
                
                <Editor2
                    data={editedData?.steps ?? []}
                    setData={(x) => handleChange('steps', x)}
                    fullScreen={true}
                    onStepChange={handleStepChange}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={onSave}>Save Changes</Button>
            </DialogActions>
        </Dialog>
    );
};

export default EditorDialog;
