import React, { useEffect, useRef, useState } from 'react';
import { images } from '../images';

const ImageWithCircleOverlay = ({ step, imageHeight }) => {
    const imageRef = useRef(null);
    const url = `https://kerdoiv.b612.hu/help/`;
    const [imageLoaded, setImageLoaded] = useState(false);
    const [imageDataLoaded, setImageDataLoaded] = useState(false);
    const { image, mark, markRadius, markRectangleWidth,
        markRectangleHeight, markXPercent, markYPercent, alt } = step;
    // const a = typeof image === 'object' ? image.image : image;
    const imageUrl = url + images[image]?.image;
    console.log(typeof image);
    
    useEffect(() => {
        console.log('stepps', step);
        const image = imageRef.current;

        const handleImageLoad = () => {
            setImageLoaded(true);
        };

        if (image) {
            image.addEventListener('load', handleImageLoad);
            return () => {
                image.removeEventListener('load', handleImageLoad);
            };
        }
    }, [imageRef, step]);

    useEffect(() => {
        // Adatok betöltése, például mark, markRadius stb.
        // ...

        // Állapot beállítása, hogy az adatok betöltődtek
        setImageDataLoaded(true);
    }, [step]);

    useEffect(() => {
        // Minden adat és a kép is betöltött
        if (imageLoaded && imageDataLoaded) {
            drawCircle();
        }
    }, [imageLoaded, imageDataLoaded, /* további függőségek */]);

    // useEffect(() => {
    //     drawCircle();
    // }, [step]);


    const handleCanvasClick = (event) => {
        const canvas = event.currentTarget;
        const rect = canvas.getBoundingClientRect();
        const clickX = ((event.clientX - rect.left) / rect.width) * 100;
        const clickY = ((event.clientY - rect.top) / rect.height) * 100;
        console.log(`Kattintás koordinátái: ${clickX}%, ${clickY}%`);
    };

    const drawCircle = () => {
        const image = imageRef.current;
        if (!image) return;

        // Távolítsuk el az előző canvas-t, ha van ilyen
        const previousCanvas = image?.nextElementSibling;
        if (previousCanvas) {
            previousCanvas.removeEventListener('click', handleCanvasClick);
            previousCanvas.remove();
        }

        const canvas = document.createElement('canvas');
        const percent = imageHeight / image.naturalHeight;

        canvas.width = (image.naturalWidth / image.naturalHeight) * imageHeight;
        canvas.height = imageHeight;
        const centerX = (markXPercent / 100) * image.width * percent;
        const centerY = (markYPercent / 100) * image.height;
        const context = canvas.getContext('2d');
        context.drawImage(image, 0, 0, image.naturalWidth * percent, image.naturalHeight * percent);
        context.globalCompositeOperation = 'source-over';

        if (mark === 'circle') {
            context.fillStyle = 'rgba(255, 0, 0, 0.5)';
            context.strokeStyle = 'rgba(255, 0, 0, 0.5)';
            context.beginPath();
            context.arc(centerX, centerY, markRadius * percent, 0, 2 * Math.PI, false);
            context.closePath();
            context.stroke();
            context.fill();
        } else {
            const rectX = centerX;
            const rectY = centerY;
            const rectWidth = ((markRectangleWidth - markXPercent) / 100) * image.width * percent;
            const rectHeight = image.height * (markRectangleHeight - markYPercent) / 100

            context.fillStyle = 'rgba(255, 0, 0, 0.1)';
            context.strokeStyle = 'rgba(255, 0, 0, 0.9)';
            context.lineWidth = 2;

            context.fillRect(rectX, rectY, rectWidth, rectHeight);
            context.strokeRect(rectX, rectY, rectWidth, rectHeight);
        }

        // Adjuk hozzá a canvas-t az img után
        image.parentElement.insertBefore(canvas, image.nextSibling);

        // Eseményfigyelő hozzáadása a canvas-hoz
        canvas.addEventListener('click', handleCanvasClick);
    };

    return (
        <>
            <img
                ref={imageRef}
                src={imageUrl}
                // alt={alt}
                height={imageHeight}
                style={{ width: 'auto', height: '500px', position: 'relative', display: 'none' }}
            />
        </>
    );
};

export default ImageWithCircleOverlay;
