import React, { useState } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, List, ListItem, ListItemText, ListItemSecondaryAction, IconButton, TextField } from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import MainLayout from '../../layouts/Main/MainLayout';
import Editor from './components/Editor';
import EditorDialog from './components/EditorDialog';
import { images } from "./images"
import { data } from './data';
import { v4 as uuidv4 } from 'uuid';
import ExampleAnimation from './ExampleAnimation';

export const HelpEditor = ({ app }) => {
  const [helpData, setHelpData] = useState(
    data
  );

  return (
    <MainLayout app={app}>
      <ExampleAnimation />
      <Editor
       key={uuidv4('2')}
        data={helpData}
        setData={setHelpData}
        fullScreen={true}
        Dialog1={EditorDialog}
      />
      <div style={{ maxWidth: '400px' }}>
        <Button onClick={() => { navigator.clipboard.writeText(JSON.stringify(helpData, null, "\t")) }}>Copy</Button>
        <pre>
          {JSON.stringify(helpData, null, "\t")}
        </pre>
      </div>
    </MainLayout>
  );
};