export const table =
{
	"_id": "groupmanagers",
	"collection": "groupmanagers",
	"fields": [
		{
			"type": "document_select",
			"required": "true",
			"label": "Felhasználó",
			"name": "userId",
			"_id": "item_1682455076938",
			"model": "users",
			"valuesLabel": "email",
			"helper": "Felhasználó",
			"valuesKey": "id"
		},
		{
			"valuesLabel": "name",
			"_id": "item_1682455116407",
			"label": "Csoport",
			"model": "groups",
			"helper": "Csoport",
			"valuesKey": "id",
			"name": "groupId",
			"required": "true",
			"type": "document_select"
		}
	],
	"icon": "AdminPanelSettingsOutlined",
	"indexFields": [
		"userId",
		"groupId"
	],
	"meta": [
		"groups",
		"users"
	],
	"name": "Ügyintézők",
	"title_create": "Új ügyintézők",
	"title_index": "Ügyintézők",
	"title_update": "Ügyintéző szerkesztése",
	"type": "array",
	"ui": "group_widget",
	"updatedAt": {
		"seconds": 1707739404,
		"nanoseconds": 800000000
	},
	"updatefields": [
		"userId",
		"groupId"
	],
	"id": "groupmanagers"
}
export default table