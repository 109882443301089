import { images } from "./images"
export const data =
[
	{
		"isAdmin": false,
		"icon": "PhonelinkLock",
		"type": "help",
		"content": "Hitelesítés",
		"description": "Bejelentkezés, regisztráció, elfelejtett jelszó",
		"steps": [
			{
				"type": "slide",
				"selector": ".login",
				"content": "Bejelentkezés",
				"description": "Üdvözöljük a bejelentkező oldalunkon! Itt egyszerűen és biztonságosan léphet be fiókjába az e-mail címével és jelszavával. ",
				"image": "signin",
				"help": null
			},
			{
				"type": "slide",
				"selector": ".login",
				"content": "Regisztráció",
				"description": "Ha még nincs regisztrált fiókja, könnyedén létrehozhatja azt a regisztrációs oldalunkon.",
				"image": "signup",
				"help": null
			},
			{
				"type": "slide",
				"selector": ".forgotpassword",
				"content": "Elfelejtett jelszó",
				"description": "Ha esetleg elfelejtette jelszavát, ne aggódjon! Az elfelejtett jelszó oldalon könnyedén visszaállíthatja azt. Csak kövesse az ott található utasításokat, és küldünk Önnek egy e-mailt a jelszó visszaállításához szükséges információkkal.",
				"image": "forgotpassword",
				"help": null
			},
			{
				"type": "slide",
				"selector": ".selectApplication",
				"content": "Rendszer kiválasztása",
				"description": "Kényelmesen választhatja ki azt a rendszert, amelybe be kíván jelentkezni vagy regisztrálni.",
				"image": "signin",
				"help": null,
				"mark": "rect",
				"markRadius": 30,
				"markXPercent": 33,
				"markYPercent": 26,
				"markRectangleWidth": 71,
				"markRectangleHeight": 34
			},
			{
				"type": "slide",
				"selector": ".registrationGoogle",
				"content": "Regisztráció Google azonosítóval",
				"description": "Regisztrációkor lehetősége van választani, hogy hagyományos e-mail és jelszó kombinációval szeretne regisztrálni, vagy egyszerűen használja Google fiókját a regisztrációhoz.",
				"image": "signup",
				"help": null,
				"mark": "rect",
				"markRadius": 30,
				"markXPercent": 13,
				"markYPercent": 73,
				"markRectangleWidth": 87,
				"markRectangleHeight": 83
			}
		]
	},
	{
		"type": "help",
		"isAdmin": true,
		"icon": "Quiz",
		"content": "Applikáció telepítése",
		"description": "A PR-VET kérdőív alkalmazásának telepítésével az ikonja megjelenik a kezdőképernyőn, így könnyedén és gyorsan elérheted a kérdőíveket. Az ikonra koppintva az alkalmazás gyorsan elindul, hasonlóan egy hagyományos alkalmazáshoz, javítva ezzel a kezelhetőséget és az indítási sebességet.",
		"steps": [
			{
				"type": "slide",
				"selector": ".dashboard2",
				"content": "Chrome böngésző indítása.",
				"description": "Az Android készülékeddel nyisd meg a Chrome böngészőt. Látogass el https://kerdoiv.b612.hu. oldalra.",
				"image": null,
				"help": null,
				"mark": "rect",
				"markRadius": 11,
				"markXPercent": 25,
				"markYPercent": 91,
				"markRectangleWidth": 38,
				"markRectangleHeight": 97
			},
			{
				"type": "slide",
				"selector": ".dashboard2",
				"content": "Útmutató Android készülékhez",
				"description": "Jelentkezz be. A nyitóoldalon vagy a profil oldalon koppints az applikáció telepítése gombra. Kövesd a képernyőn megjelenő utasításokat.",
				"image": "install1",
				"help": null,
				"mark": "circle",
				"markRadius": 21,
				"markXPercent": 78,
				"markYPercent": 44,
				"markRectangleWidth": 38,
				"markRectangleHeight": 97
			},
			{
				"type": "slide",
				"selector": ".dashboard2",
				"content": "iPhone vagy iPad készülékre.",
				"description": "Az címsor jobb felső sarkában koppints a Megosztás lehetőségre. Keresd meg és koppints a Hozzáadás a kezdőképernyőhöz opcióra. Ellenőrizd vagy szerkeszd az oldal részleteit, majd koppints a Hozzáadás gombra.",
				"image": "install2",
				"help": null,
				"mark": "circle",
				"markRadius": 21,
				"markXPercent": 81,
				"markYPercent": 9,
				"markRectangleWidth": 38,
				"markRectangleHeight": 97
			}
		]
	},
	{
		"type": "help",
		"isAdmin": false,
		"icon": "House",
		"content": "Kezdőoldal",
		"description": "A kezdőoldalon elérhető funkciók",
		"steps": [
			{
				"type": "slide",
				"selector": ".dashboard1",
				"content": "Funkciók és információk",
				"description": "A kezdőoldalon könnyedén hozzáférhet az applikáció legfontosabb információihoz és funkcióihoz.",
				"image": "dashboard2",
				"help": null
			},
			{
				"type": "slide",
				"selector": ".dashboard4",
				"content": "Menüsáv",
				"description": "Az oldal alján könnyen elérheti a rendszer főbb menüpontjait, amelyek közvetlenül kapcsolódnak az Ön felhasználói élményéhez. Itt találja meg a kitöltendő kérdőíveketaz értékeléseket és a profil menüt.",
				"image": "dashboard2",
				"help": null,
				"mark": "rect",
				"markRadius": 11,
				"markXPercent": 8,
				"markYPercent": 91,
				"markRectangleWidth": 92,
				"markRectangleHeight": 98
			},
			{
				"type": "slide",
				"selector": ".dashboard4",
				"content": "Súgó",
				"description": "A képernyő jobb felső sarkában találja a súgót",
				"image": "dashboard2",
				"help": null,
				"mark": "circle",
				"markRadius": 11,
				"markXPercent": 87.5,
				"markYPercent": 11.3,
				"markRectangleWidth": 91,
				"markRectangleHeight": 13
			},
			{
				"type": "slide",
				"selector": ".dashboard2",
				"content": "Jelentkezésekről szóló értesítések",
				"description": " Mindig naprakész lehet a legújabb fejleményekkel kapcsolatban, hiszen itt értesülhet a beérkező jelentkezésekről.",
				"image": "dashboard2",
				"help": null,
				"mark": "rect",
				"markRadius": 9,
				"markXPercent": 8,
				"markYPercent": 43,
				"markRectangleWidth": 90,
				"markRectangleHeight": 52
			},
			{
				"type": "slide",
				"selector": ".dashboard3",
				"content": "Kérdőívek és értékelések értesítései",
				"description": "Ne maradjon le semmiről! A kezdőoldalon könnyen megtalálja azokat az értesítéseket, amelyek a kitöltendő kérdőívekről és kapott értékelésekről szólnak.",
				"image": "dashboard2",
				"help": null,
				"mark": "rect",
				"markRadius": 11,
				"markXPercent": 8,
				"markYPercent": 30,
				"markRectangleWidth": 92,
				"markRectangleHeight": 42
			},
			{
				"type": "slide",
				"selector": ".dashboardQRCode",
				"content": "QR kódok és csoportjelentkezés",
				"description": "A közvetlen elérhetőség érdekében itt találhatóak a csoportok QR kódjai, amelyeket gyorsan beolvashat. Emellett egyszerűen jelentkezhet be csoportokba a \"Jelentkezés csoportba\" gomb segítségével.",
				"image": "dashboard2",
				"help": null,
				"mark": "rect",
				"markRadius": 11,
				"markXPercent": 8,
				"markYPercent": 54,
				"markRectangleWidth": 92,
				"markRectangleHeight": 67
			}
		]
	},
	{
		"type": "help",
		"isAdmin": false,
		"icon": "GroupAdd",
		"content": "Csoport feliratkozás",
		"description": "A Csoport feliratkozás lehetősége az alkalmazás főoldaláról és a felhasználó profiljából is gyorsan elérhető, biztosítva a könnyű és intuitív feliratkozási folyamatot.",
		"steps": [
			{
				"type": "slide",
				"selector": ".profile1",
				"content": "Feliratkozás",
				"description": "A feliratkozás opciója mind a főoldalon, mind a felhasználó profiljában szerepel, így bárhonnan könnyen elérhető.",
				"image": "dashboard2",
				"help": null,
				"mark": "circle",
				"markRadius": 41,
				"markXPercent": 78.8,
				"markYPercent": 60.6
			},
			{
				"type": "slide",
				"selector": ".profile1",
				"content": "Csoport QR kódjának beolvasása",
				"description": "Az új csoportokhoz való csatlakozáshoz egyszerűen olvassuk le a csoport QR kódját.",
				"image": "subscribe1"
			},
			{
				"type": "slide",
				"selector": ".profile1",
				"content": "Jelentkezés megerősítése",
				"description": " Amennyiben sikeres a beolvasás és még nem vagyunk tagok, megjelenik egy jelentkezési megerősítési ablak. Kattintsunk a \"Jelentkezem\" gombra",
				"image": "subscribe2"
			},
			{
				"type": "slide",
				"selector": ".profile1",
				"content": "Sikertelen jelentkezés",
				"description": "Ha már tagok vagyunk, vagy már jelentkeztünk, egy hibaüzenet tájékoztat minket erről.",
				"image": "subscribe3"
			},
			{
				"type": "slide",
				"selector": ".profile1",
				"content": "Jelentkezés nyomonkövetése",
				"description": "A sikeres jelentkezés megjelenik a Csoportjainknál a Feliratkozások listájában.",
				"image": "subscribe4",
				"help": null,
				"mark": "rect",
				"markXPercent": 8,
				"markYPercent": 37,
				"markRectangleWidth": 85,
				"markRectangleHeight": 56
			},
			{
				"type": "slide",
				"selector": ".profile1",
				"content": "Értesítés a jelentkezésről",
				"description": "A csoport adminisztrátorai értesítést kapnak a jelentkezésről, és lehetőségük van elfogadni vagy elutasítani azt.",
				"image": "subscribe5",
				"help": null,
				"mark": "rect",
				"markXPercent": 11,
				"markYPercent": 55,
				"markRectangleWidth": 87,
				"markRectangleHeight": 62
			},
			{
				"type": "slide",
				"selector": ".profile1",
				"content": "Értesítés a főoldalon",
				"description": "Az értesítés megjelenik a főoldalon is, így könnyen észlelhetjük, hogy jelentkezésünk feldolgozás alatt áll.",
				"image": "subscribe6",
				"help": null,
				"mark": "rect",
				"markXPercent": 11,
				"markYPercent": 42,
				"markRectangleWidth": 89,
				"markRectangleHeight": 49
			}
		]
	},
	{
		"type": "help",
		"isAdmin": false,
		"icon": "Groups",
		"content": "Csoportok",
		"description": "A csoportok kezelése rendkívül egyszerű és kényelmes az alkalmazásban, melynek során többféle funkcionalitáshoz is hozzáférhetünk.",
		"steps": [
			{
				"type": "slide",
				"selector": ".groups1",
				"content": "Csoportok listája",
				"description": "A csoportok menüpont a felhasználó profilján keresztül, valamint az egyes csoportok főoldalán keresztül is könnyen elérhető.",
				"image": {
					"name": "Csoportok",
					"image": "groups/mygroups1.png"
				}
			},
			{
				"type": "slide",
				"selector": ".groups2",
				"content": "QR Kód",
				"description": "A QR Kód fül alatt látható a csoport egyedi QR kódja, amely lehetővé teszi a gyors csatlakozást a csoportba. Egyszerűen beolvasható, és azonnal elérhetővé teszi a csoport főoldalát",
				"image": {
					"name": "Csoport - adatlap",
					"image": "groups/mygroups2.png"
				}
			},
			{
				"type": "slide",
				"selector": ".groups3",
				"content": "Tagok",
				"description": "A Tagok fül alatt teljes körűen megtekinthető a csoport összes tagja. Ez egy átlátható lista, amely bemutatja a csoport tagjait.",
				"image": {
					"name": "Csoport - felhasználók",
					"image": "groups/mygroups3.png"
				}
			},
			{
				"type": "slide",
				"selector": ".groups4",
				"content": "Admin",
				"description": "Az Admin fül alatt a csoporttagok számára lehetőség nyílik a csoporttagság kezelésére. ",
				"image": {
					"name": "Csoport - adminisztráció",
					"image": "groups/mygroups4.png"
				}
			},
			{
				"type": "slide",
				"selector": ".groups5",
				"content": "Feliratkozások",
				"description": "A Feliratkozások fül lehetőséget ad a csoport adminisztrátorainak a feliratkozások kezelésére.",
				"image": {
					"name": "Csoport - jelentkezők",
					"image": "groups/mygroups5.png"
				}
			}
		]
	},
	{
		"type": "help",
		"isAdmin": true,
		"icon": "Quiz",
		"content": "Kérdőívek készítése",
		"description": "Üdvözöljük a kérdőívek skészítésének dokumentációjában, ahol részletes leírást talál arról, hogyan hozhat létre és kezelhet kérdőíveket az alkalmazásban. Az alábbiakban áttekintjük a fő lépéseket, amelyekkel könnyedén elkészítheti saját kérdőíveit.",
		"steps": [
			{
				"type": "slide",
				"selector": ".dashboard2",
				"content": "Kérdőívek Menüpont",
				"description": "A \"Kérdőívek\" menüponton keresztül érhető el az összes létrehozott kérdőív. Itt kezelheti a már meglévő kérdőíveket vagy készíthet újat",
				"image": {
					"name": "Kezdőoldal",
					"image": "dashboard/dashboard2.png"
				},
				"help": null,
				"mark": "rect",
				"markRadius": 11,
				"markXPercent": 25,
				"markYPercent": 91,
				"markRectangleWidth": 38,
				"markRectangleHeight": 97
			},
			{
				"type": "slide",
				"selector": ".manage1",
				"content": "Kérdőívek listája",
				"description": "A \"Kérdőívek\" menüpont egy könnyen navigálható felhasználói felületet kínál, ahol az összes már elkészített kérdőív listázva van.",
				"image": {
					"name": "Kérdőívek",
					"image": "manage/manage1.png"
				},
				"help": null,
				"mark": "rect",
				"markRadius": 11,
				"markXPercent": 7,
				"markYPercent": 17,
				"markRectangleWidth": 88,
				"markRectangleHeight": 83
			},
			{
				"type": "slide",
				"selector": ".manage2",
				"content": "Kérdőív készítése",
				"description": "A jobb alsó sarokban található egy \"+\" gomb, amely lehetővé teszi az új kérdőív létrehozását. Egyetlen kattintással elindíthatja a kérdőív készítésének folyamatát",
				"image": {
					"name": "Kérdőívek",
					"image": "manage/manage1.png"
				},
				"help": null,
				"mark": "circle",
				"markRadius": 27,
				"markXPercent": 84.4,
				"markYPercent": 85.5,
				"markRectangleWidth": 38,
				"markRectangleHeight": 97
			},
			{
				"type": "slide",
				"selector": ".start1",
				"content": "Kérdőívek Menüpont",
				"description": "Indítás után egy rövid ismertetővel találkozik, amely segít a kérdőív létrehozásában.",
				"image": {
					"name": "Kérdőív készítés 1.",
					"image": "manage/start1.png"
				},
				"help": null
			},
			{
				"type": "slide",
				"selector": ".start2",
				"content": "Sablon választása",
				"description": "A következő oldalon válassza ki a megfelelő kérdőív sablont.",
				"image": {
					"name": "Kérdőív készítés 2.",
					"image": "manage/start2.png"
				},
				"help": null
			},
			{
				"type": "slide",
				"selector": ".start3",
				"content": "Csoport(ok) kiválasztása",
				"description": "Adja meg a csoportot vagy csoportokat, akiknek a kérdőívet kívánja küldeni.",
				"image": {
					"name": "Kérdőív készítés 3.",
					"image": "manage/start3.png"
				},
				"help": null
			},
			{
				"type": "slide",
				"selector": ".start4",
				"content": "Kezdődátum Beállítása",
				"description": "Válassza ki a kitöltés kezdődátumát.",
				"image": {
					"name": "Kérdőív készítés 4.",
					"image": "manage/start4.png"
				},
				"help": null
			},
			{
				"type": "slide",
				"selector": ".start5",
				"content": "Határidő Megadása",
				"description": "Állítsa be a kitöltés határidejét.",
				"image": {
					"name": "Kérdőív készítés 5.",
					"image": "manage/start5.png"
				},
				"help": null
			},
			{
				"type": "slide",
				"selector": ".start6",
				"content": "Összefoglaló",
				"description": "A következő oldalon összefoglalhatja a beállított adatokat, majd az \"Indítás\" gombbal elkészítheti a kérdőívet.",
				"image": {
					"name": "Kérdőív készítés 6.",
					"image": "manage/start6.png"
				},
				"help": null
			},
			{
				"type": "title",
				"selector": ".title",
				"content": "Sikeres mentés utáni események",
				"description": "A kérdőív létrehozása után több \"esemény\" történik."
			},
			{
				"type": "slide",
				"selector": ".start7",
				"content": "Sikeres mentés",
				"description": "A kérdőív létrehozása után egy sikeres mentés oldalon találja magát",
				"image": {
					"name": "Kérdőív készítés 7.",
					"image": "manage/start7.png"
				},
				"help": null
			},
			{
				"type": "slide",
				"selector": ".start8",
				"content": "Kérdőívek Listájának frissítése",
				"description": "A létrehozott kérdőív megjelenik a \"Kérdőívek\" menüpontban.",
				"image": {
					"name": "Kérdőív készítés 8.",
					"image": "manage/start8.png"
				},
				"help": null,
				"mark": "rect",
				"markRadius": 8,
				"markXPercent": 7,
				"markYPercent": 17,
				"markRectangleWidth": 88,
				"markRectangleHeight": 31
			},
			{
				"type": "slide",
				"selector": ".start9",
				"content": "Csoport tagjainak értesítése",
				"description": "A kiválasztott csoport tagjai értesítést kapnak a kitöltés lehetőségéről, és megkezdhetik a kérdőív kitöltését.",
				"image": {
					"name": "Kérdőív készítés 9.",
					"image": "manage/start9.png"
				},
				"help": null,
				"mark": "rect",
				"markRadius": 8,
				"markXPercent": 7,
				"markYPercent": 17,
				"markRectangleWidth": 88,
				"markRectangleHeight": 31
			}
		]
	},
	{
		"type": "help",
		"isAdmin": false,
		"icon": "ThumbsUpDown",
		"content": "Kérdőív kitöltése",
		"description": "A kitöltés menüpont az alkalmazásban lehetővé teszi a felhasználók számára, hogy könnyen részt vegyenek az értékelési folyamatban, amely számos lépést tartalmaz a felhasználók érzelmi állapotától a visszajelzésig.",
		"steps": [
			{
				"type": "slide",
				"selector": ".sampling1",
				"content": "Kérdőív kiválasztása",
				"description": "A kitölthető kérdőívek listája a kitöltés menüpontban található. A listából válaszd ki a kérdőívet!",
				"image": {
					"name": "Kitöltés 1.",
					"image": "sampling/sampling1.png"
				},
				"help": null,
				"mark": "rect",
				"markRadius": 12,
				"markXPercent": 43,
				"markYPercent": 91,
				"markRectangleWidth": 56,
				"markRectangleHeight": 98
			},
			{
				"type": "slide",
				"selector": ".sampling2",
				"content": "Érzelmi állapot értékelése",
				"description": "A folyamat első lépéseként a felhasználóknak értékelniük kell az érzelmi állapotukat, ami a kitöltés kiindulópontja.",
				"image": {
					"name": "Kitöltés 2.",
					"image": "sampling/sampling2.png"
				}
			},
			{
				"type": "slide",
				"selector": ".sampling2",
				"content": "Visszajelzés",
				"description": "Az értékelés után a felhasználók azonnal kapnak egy visszajelzést érzelmi állapotukról, ami segít a tudatosabb érzések és gondolatok kialakításában.",
				"image": {
					"name": "Kitöltés 3.",
					"image": "sampling/sampling3.png"
				}
			},
			{
				"type": "slide",
				"selector": ".sampling2",
				"content": "Értékelendő személyek kiválasztása",
				"description": "A felhasználóknak lehetőségük van kiválasztani az értékelni kívánt személyeket.",
				"image": {
					"name": "Kitöltés 4.",
					"image": "sampling/sampling4.png"
				}
			},
			{
				"type": "slide",
				"selector": ".sampling2",
				"content": "Értékelés elkezdése",
				"description": "A kitöltés elején megjelenik az első értékelendő személy neve és profilképe.",
				"image": {
					"name": "Kitöltés 5.",
					"image": "sampling/sampling5.png"
				}
			},
			{
				"type": "slide",
				"selector": ".sampling2",
				"content": "Értékelés",
				"description": "Majd a felhasználók kitölthetik az értékelést.",
				"image": {
					"name": "Kitöltés 6.",
					"image": "sampling/sampling6.png"
				}
			},
			{
				"type": "slide",
				"selector": ".sampling2",
				"content": "Értékelt változása",
				"description": "A folyamat során a következő értékelendő személyek is megjelennek.",
				"image": {
					"name": "Kitöltés 5.",
					"image": "sampling/sampling7.png"
				}
			},
			{
				"type": "slide",
				"selector": ".sampling2",
				"content": "Értékelés folytatása",
				"description": "Majd a felhasználók kitölthetik az új értékelt személy értékelését.",
				"image": {
					"name": "Kitöltés 8.",
					"image": "sampling/sampling8.png"
				}
			},
			{
				"type": "slide",
				"selector": ".sampling2",
				"content": "Értékelés nyomonkövetése",
				"description": "Az értékelés folyamatát alul láthatjuk, így könnyen nyomon követhetjük, hogy hány értékelést adtunk már le.",
				"image": {
					"name": "Kitöltés 9.",
					"image": "sampling/sampling9.png"
				}
			},
			{
				"type": "slide",
				"selector": ".sampling2",
				"content": "Összefoglaló és mentés",
				"description": "A végén egy összefoglaló oldalon láthatjuk az összes kitöltött értékelést. Az \"Indítás\" gombbal sikeresen menthetjük az értékeléseinket.",
				"image": {
					"name": "Kitöltés 10.",
					"image": "sampling/sampling10.png"
				}
			},
			{
				"type": "slide",
				"selector": ".sampling2",
				"content": "Sikeres entés",
				"description": "A sikeres mentés után megjelenik egy üzenet, amely megerősíti, hogy az értékelés sikeresen mentésre került.",
				"image": {
					"name": "Kitöltés 11.",
					"image": "sampling/sampling11.png"
				}
			},
			{
				"type": "slide",
				"selector": ".sampling2",
				"content": "Értesítés az értékelt személyeknek",
				"description": "Az értékelt személyek értesítést kapnak arról, hogy új értékelés érkezett. Az értékelés megjelenik a listájukban.",
				"image": {
					"name": "Kitöltés 14.",
					"image": "sampling/sampling14.png"
				},
				"help": null,
				"mark": "rect",
				"markRadius": 12,
				"markXPercent": 52,
				"markYPercent": 91,
				"markRectangleWidth": 70,
				"markRectangleHeight": 98
			},
			{
				"type": "slide",
				"selector": ".sampling2",
				"content": "Értesítés a kezdő oldalon",
				"description": "Az értesítés a kezdőoldalon is megjelenik",
				"image": {
					"name": "Kitöltés 13.",
					"image": "sampling/sampling13.png"
				},
				"help": null,
				"mark": "rect",
				"markRadius": 12,
				"markXPercent": 9,
				"markYPercent": 16,
				"markRectangleWidth": 90,
				"markRectangleHeight": 26
			},
			{
				"type": "slide",
				"selector": ".sampling2",
				"content": "Értékelés megtekintése",
				"description": "Az értékelt személyek ANONÍM MÓDON megtekinthetik az értékelést, biztosítva ezzel a személyes adatok védelmét és a bizalmi légkört az értékelési folyamatban.",
				"image": {
					"name": "Kitöltés 15.",
					"image": "sampling/sampling15.png"
				}
			}
		]
	},
	{
		"type": "help",
		"isAdmin": false,
		"icon": "BarChart",
		"content": "Értékelések",
		"description": "Az értékelések a főmenüből érhetőek el",
		"steps": [
			{
				"type": "slide",
				"selector": ".evaluations1",
				"content": "Funkciók",
				"description": "Az \"Értékelések\" menüpont alatt könnyen navigálhat az összes elérhető értékeléshez. Itt találja meg azokat a visszajelzéseket, amelyeket a felhasználók vagy más résztvevők adtak.",
				"image": {
					"name": "Kezdőoldal",
					"image": "dashboard/dashboard2.png"
				},
				"help": null,
				"mark": "rect",
				"markRadius": 11,
				"markXPercent": 59,
				"markYPercent": 91,
				"markRectangleWidth": 76,
				"markRectangleHeight": 97
			},
			{
				"type": "slide",
				"selector": ".evaluations2",
				"content": "Olvasatlan értékelések",
				"description": " A menüpont mellett található egy \"badge\", amely az olvasatlan értékelések számát mutatja. Ez a vizuális jelzés segíti Önt azonosítani azokat az értékeléseket, amelyek még elolvasatlanok",
				"image": {
					"name": "Értékelések listája",
					"image": "evaluations/evaluations1.png"
				},
				"help": null,
				"mark": "circle",
				"markRadius": 14,
				"markXPercent": 71.3,
				"markYPercent": 92.4,
				"markRectangleWidth": 76,
				"markRectangleHeight": 97
			},
			{
				"type": "slide",
				"selector": ".evaluations3",
				"content": "Értékelés részletei",
				"description": "Amikor egy értékelést kiválaszt, láthatóvá válnak annak főbb statisztikai adatai. Ez lehetőséget biztosít a gyors áttekintésre és az értékelés főbb jellemzőinek megértésére.",
				"image": {
					"name": "Értékelések listája",
					"image": "evaluations/evaluations2.png"
				},
				"help": null,
				"mark": "rect",
				"markRadius": 11,
				"markXPercent": 8,
				"markYPercent": 42,
				"markRectangleWidth": 92,
				"markRectangleHeight": 90
			},
			{
				"type": "slide",
				"selector": ".evaluations4",
				"content": "Egyes értékelések Eredményei",
				"description": "Az értékelés részletes nézetében a felhasználóknak lehetőségük van megtekinteni az egyes értékelések eredményeit.",
				"image": {
					"name": "Értékelés",
					"image": "evaluations/evaluations3.png"
				},
				"help": null,
				"mark": "rect",
				"markRadius": 11,
				"markXPercent": 8,
				"markYPercent": 66,
				"markRectangleWidth": 92,
				"markRectangleHeight": 89
			}
		]
	},
	{
		"type": "help",
		"isAdmin": false,
		"icon": "PendingActions",
		"content": "Újdonságok 2023.10.10",
		"description": "Az új verzióban számos fejlesztés történt. A legfontosabbak a következők: ",
		"steps": [
			{
				"type": "slide",
				"selector": ".selectApplication",
				"content": "Rendszer kiválasztása",
				"description": "A bejelentkező/regsztrációs oldalon ki lehet választani, hogy a \"demó\" vagy az \"éles\" rendszeren szeretnénk dolgozni.",
				"image": {
					"name": "Bejelentkezés",
					"image": "authentication/signin.png"
				},
				"help": null,
				"mark": "rect",
				"markRadius": 30,
				"markXPercent": 33,
				"markYPercent": 26,
				"markRectangleWidth": 71,
				"markRectangleHeight": 34
			},
			{
				"type": "slide",
				"selector": ".registrationGoogle",
				"content": "Regisztráció Google azonosítóval",
				"description": "Lehet regisztrálni Google azonosítóval is (ebben az esetben nem kell jelszót beállítani)",
				"image": {
					"name": "Regisztráció",
					"image": "authentication/signup.png"
				},
				"help": null,
				"mark": "rect",
				"markRadius": 30,
				"markXPercent": 13,
				"markYPercent": 73,
				"markRectangleWidth": 87,
				"markRectangleHeight": 83
			},
			{
				"type": "slide",
				"selector": ".dashboardQRCode",
				"content": "Csoport Qr kód a kezdőoldalon",
				"description": "A nyitó oldalon közvetlen elérhetőek a csoportok QR kódjai, valamint a \"jelentkezés csoportba\" gomb.",
				"image": {
					"name": "Kezdőoldal",
					"image": "dashboard/dashboard.png"
				},
				"help": null,
				"mark": "rect",
				"markRadius": 11,
				"markXPercent": 7,
				"markYPercent": 14,
				"markRectangleWidth": 92,
				"markRectangleHeight": 37
			},
			{
				"type": "title",
				"selector": ".title",
				"content": "Dokumentáció",
				"description": "További segédanyagok a rendszer használatához"
			},
			{
				"type": "link",
				"selector": ".authentication",
				"content": "Bejelentkezés, regisztráció, elfelejtett jelszó",
				"description": "Bejelentkezés, regisztráció, elfelejtett jelszó",
				"link": "authentication"
			}
		]
	}
]
    ;