import React, { useState } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, List, ListItem, ListItemText, ListItemSecondaryAction, IconButton, TextField } from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import EditorDialog from './EditorDialog';
import EditorDialog2 from './EditorDialog2';
import ImageWithCircleOverlay from './ImageWithCircleOverlay';
import { images } from '../images';

const Editor2 = ({ data, setData, fullScreen = true }) => {
    const [editIndex, setEditIndex] = useState(null);
    const [open, setOpen] = useState(false);
    const [editedData, setEditedData] = useState({});

    const handleNewItemClick = () => {
        setOpen(true);
        setEditIndex(null);
        setEditedData({});
    };

    const handleEditClick = (index) => {
        setEditIndex(index);
        setEditedData(data[index]);
        let step = data[index];
        const x = Object.keys(images);
        if (typeof step?.image === 'object') {
            const xx = x.filter((a) => images[a].name === step.image.name)
            step = {
                ...step, image: xx[0]
            }
            setEditedData(step);
        }
        console.log('xxxx', step);
        setOpen(true);
    };

    const handleDeleteClick = (index) => {
        const newData = [...data];
        newData.splice(index, 1);
        setData(newData);
    };

    const handleDragEnd = (result) => {
        if (!result.destination) return;

        const newData = [...data];
        const [reorderedItem] = newData.splice(result.source.index, 1);
        newData.splice(result.destination.index, 0, reorderedItem);

        setData(newData);
    };

    const handleDialogClose = () => {
        setEditIndex(null);
        setEditedData({});
        setOpen(false);
    };

    const handleSaveChanges = () => {
        
        const newData = [...data];
        console.log(newData);
        if (editIndex !== null) {
            newData[editIndex] = editedData;
        } else {
            newData.push(editedData);
        }
        setData(newData);
        handleDialogClose();
    };

    const handleChange = (field, value) => {
        setEditedData((prev) => ({ ...prev, [field]: value }));
    };

    return (
        <div>
            <Button onClick={handleNewItemClick}>Új elem</Button>

            <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId="droppable">
                    {(provided) => (
                        <List {...provided.droppableProps} ref={provided.innerRef}>
                            {data.map((item, index) => (
                                <Draggable key={index} draggableId={`item-${index}`} index={index}>
                                    {(provided) => (
                                        <ListItem
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}  // Fogó elem hozzáadása
                                            ref={provided.innerRef}
                                        >
                                            <ListItemText primary={item.content} secondary={item.description} />
                                            <ListItemSecondaryAction>
                                                <IconButton onClick={() => handleEditClick(index)}>
                                                    <Edit />
                                                </IconButton>
                                                <IconButton onClick={() => handleDeleteClick(index)}>
                                                    <Delete />
                                                </IconButton>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </List>
                    )}
                </Droppable>
            </DragDropContext>

            <EditorDialog2
                open={open}
                onClose={handleDialogClose}
                onSave={handleSaveChanges}
                editedData={editedData}
                handleChange={handleChange}
                fullScreen={fullScreen}
            />
        </div>
    );
};

export default Editor2;
